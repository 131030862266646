import LazyImg from "@/Components/LazyImg";
import Modal from "@/Components/Modal";
import { Button } from "@/Components/ui/button";
import { Card } from "@/Components/ui/card";
import { ScrollArea } from "@/Components/ui/scroll-area";
import { useProductCategories } from "@/Hooks/CategoriesHook";
import { lang } from "@/Hooks/Functions";
import { cn } from "@/lib/utils";
import { JSONGetRequest } from "@/Hooks/JSONRequest";
import Loader from "@/Modules/Loader";
import { Icon } from "@iconify/react";
import { bothAction, bothState } from "@state/mode-both";
import { useCallback, useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { useSnapshot } from "valtio";

export default function ProductSelectModal({ show = false, onClose = () => { }, onSelect = () => { } }) {

    const { categories } = useProductCategories();

    const snap = useSnapshot(bothState);

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchProducts = useCallback((category_id) => {
        JSONGetRequest({
            href: route("products.paginate", {
                per_page: 20,
                category_id: category_id
            }),
            onResult: (val) => setItems(val.data),
            onLoading: (val) => setLoading(val),
        });
    }, []);

    useEffect(() => {
        fetchProducts(snap.selectedProductCategory);
    }, [snap.selectedProductCategory])

    return (
        <Modal className="max-w-7xl" closable={!snap.predesignsModalForce} show={show} onClose={onClose} title="Select Product">
            <div className="w-full grid md:grid-cols-5 grid-cols-1 gap-2 text-foreground">
                <div className="col-span-1 flex md:min-h-[70vh] min-h-full md:flex-col flex-row flex-wrap md:gap-2 gap-1 md:p-2 p-0 text-white">
                    <Button size="sm" variant="link" className={cn("md:w-full w-fit inline-flex justify-start", 'all' === snap.selectedProductCategory ? '!font-semibold text-teal-600' : '')} onClick={() => bothAction.setBothState('selectedProductCategory', 'all')}>
                        {lang("All Categories")}
                    </Button>
                    {categories?.length > 0 &&
                        categories.map((cat) =>
                            <Button variant="link" size="sm" onClick={() => bothAction.setBothState('selectedProductCategory', cat.id)}
                                key={cat.id}
                                className={cn("md:w-full w-fit inline-flex justify-start", cat.id === snap.selectedProductCategory ? '!font-semibold text-teal-600' : '')}
                            >
                                {lang(cat.name)}
                            </Button>
                        )}
                </div>
                <div className="md:col-span-4 col-span-1 flex flex-col justify-start items-center p-4 max-h-[70vh]">
                    {loading && <div className="grid w-full gap-4 mb-2 md:grid-cols-4 grid-cols-2">
                        {[1, 2, 3, 4].map(i => <Loader key={i} className="w-full min-h-[300px] my-1 h-full" />)}
                    </div>}
                    {!loading && items?.length === 0 && <div className="!mx-auto min-h-[50vh] flex flex-col justify-center items-center gap-2 aspect-auto">
                        <Icon icon="mdi:package-variant-closed" height={100} className="text-muted-foreground" />
                        <span className="text-xl font-semibold">No filtered product found.</span>
                    </div>}

                    <ScrollArea className="w-full pr-3">
                        <ResponsiveMasonry
                            columnsCountBreakPoints={{ 750: 2, 900: 3, 1024: 4 }}
                        >
                            <Masonry gutter="0.5em">
                                {items.map((i) => (
                                    <Card
                                        key={i.id}
                                        className="flex border relative flex-col justify-center overflow-hidden items-center gap-2 group"
                                    >
                                        <LazyImg src={i.url_thumb}
                                            className="min-h-[100px] max-h-[300px] group-hover:scale-105 col-span-1 bg-white relative bg-cover bg-no-repeat bg-center flex flex-col justify-center items-center"
                                        />
                                        <p className="p-2 w-full text-sm font-semibold">
                                            {i.title}
                                        </p>
                                        <div className="flex flex-row justify-between items-center w-full p-2 gap-4">
                                            <span className="text-xs font-semibold text-muted-foreground">
                                                {i.range_price}
                                            </span>
                                            <Button size="xs" onClick={() => onSelect(i)} >
                                                Select
                                            </Button>
                                        </div>
                                    </Card>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </ScrollArea>
                </div>

            </div>
        </Modal >
    );
}
